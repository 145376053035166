<template>
  <div>
    <!-- {{ table }} -->
    <h3>
      ID {{ siteView.site_id }} ◊◊◊
      <a :href="`http://${siteView.domain}`" target="_blank">{{
        siteView.site_name
      }}</a>
      ◊◊◊ account {{ siteView.account_id }}: {{ siteView.account_name }} ◊◊◊
      property {{ siteView.name }}
    </h3>
    <!-- UA site id: ${siteView.site_id} ◊◊◊ -->

    <v-progress-linear
      v-show="contentLoading"
      indeterminate
      :active="contentLoading"
      class="mt-5"
    ></v-progress-linear>
    <bar-chart
      class-names="mt-5"
      :title="`[${table}] ◊◊◊ View: ${siteView.id} ◊◊◊ web_property_id: ${
        siteView.web_property_id
      } ◊◊◊ created: ${truncate(siteView.created, 10, '')} `"
      :subTitle="null"
      :series="series"
      :labels="labels"
      :yAxis="yAxis"
      :colors="colors"
      :formatter="formatter"
    ></bar-chart>
  </div>
</template>

<script>
import axios from "@axios";
import BarChart from "@/components/common/charts/BarChart";
import { formatNumber, truncate } from "@/utils/formatting";

// ua__site = {
//   account_id: 1658521,
//   account_name: "Mondadori Digital France",
//   site_id: 13,
//   site_name: "Closer",
//   id: 3038840,
//   domain: "www.closermag.fr",
//   website_url: "https://www.closermag.fr",
//   name: "_Global Closer",
//   kind: "analytics#profile",
//   type: "WEB",
//   web_property_id: "UA-1658521-3",
//   internal_web_property_id: "2974904",
//   created: "2007-04-18T08:39:31",
// };

export default {
  name: "DataTimespanCountsSiteViewChart",
  components: {
    BarChart,
  },
  async created() {
    await this.getData();
  },
  props: {
    componentKey: {
      type: Number,
      default: () => 1,
    },
    table: {
      type: String,
      //default: () => [],
    },
    frequency: {
      type: Number,
      default: () => 5,
    },
    siteView: {
      type: Object,
      //default: () => [],
    },
  },
  data() {
    return {
      contentLoading: false,
      series: [],
      labels: [],
      colors: ["#4285F4"],
      formatter: (val) => formatNumber(val),
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
    };
  },
  methods: {
    async getData() {
      try {
        this.contentLoading = true;
        const response = await axios.get(
          "/universal-analytics/timespan-counts",
          {
            params: {
              table: this.table,
              frequency: this.frequency,
              view_id: this.siteView.id,
            },
          }
        );
        this.series = [
          {
            name: "Records count by month",
            data: response.data.map((obj) => obj.count),
          },
        ];
        this.labels = response.data.map((obj) => obj.date);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
      this.contentLoading = false;
    },
  },
  watch: {
    async table() {
      await this.getData();
    },
  },
  computed: {
    truncate() {
      return truncate;
    },
  },
};
</script>

<style scoped></style>
